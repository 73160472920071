gsap.registerPlugin(MotionPathPlugin, ScrollTrigger);

// Start Black and white intro wrapper
gsap.to(".intro", {
    "--target": "0%",
    ease: "none",
    scrollTrigger: {
        trigger: ".intro",
        start: "top top",
        end: "+=1000",
        pin: true,
        scrub: 1
    }
});
// End Black and white intro wrapper

// Start path slide down
gsap.timeline({
    scrollTrigger: {trigger: "#path", start: 'top top', end: 'bottom 200px', scrub: true},
    ease: "none"
}).to("#box", {
    motionPath: {
        path: "#path",
        align: "#path",
        autoRotate: true,
        alignOrigin: [0.5, 0.5]
    }
}, 0)
// End path slide down

//Start We create the future
window.addEventListener("load", () => {
    gsap
        .timeline({
            scrollTrigger: {
                trigger: ".future-wrapper",
                start: "top top",
                end: "+=100%",
                pin: true,
                scrub: true,
            }
        })
        .to("img", {
            scale: 2,
            z: 350,
            transformOrigin: "center center",
            ease: "power1.inOut"
        })
        .to("h2", {
            opacity: 1,
            ease: "power1.inOut"
        })
        .to(
            ".welcome-wrapper",
            {
                scale: 1.1,
                transformOrigin: "center center",
                ease: "power1.inOut"
            },
            "<"
        )


});
//End We create the future

// Start Lenis Scrolling

const lenis = new Lenis();

lenis.on("scroll", ScrollTrigger.update);

gsap.ticker.add((time) => {
    lenis.raf(time * 1000);
});

gsap.ticker.lagSmoothing(0);


//Start Lenis Scrolling


// Start Contact Us Form

gsap.to(".mail-wrapper", {
    yPercent: -150,
    ease: "none",
    scrollTrigger: {
        trigger: ".footer-section",
        // start: "top bottom", // the default values
        // end: "bottom top",
        scrub: true
    },
});

// End Contact Us form
